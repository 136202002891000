import { 
    Alert, AlertTitle, Avatar, Button, Chip,
    Tooltip, TableContainer, Table, Paper, TableBody, TableRow, Typography 
} from '@mui/material';
import { useState } from 'react'
import { basicProps, orderType } from '../../../utils/interfaces';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ShowOrderStaus from './ShowOrderStaus';
import { Link, useLocation } from 'react-router-dom';
import { cftTokenInfo, copyClipboard, publicPath, readabilityNumber } from '../../../functions/basicFunctions';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CancelIcon from '@mui/icons-material/Cancel';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import InfoPopup from '../../../components/InfoPopup';
import AdminMessage from './AdminMessage';
import ListInstructions from './ListInstructions';

interface myProps extends basicProps {
    orderData: orderType;
    confirmPayment: () => void,
    cancelOrder: () => void
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#B1B1B1",
      color: theme.palette.common.black,
      fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

export default function BuyWithCftInstructions(props: myProps) {

    const location = useLocation();
    const basicDatas = props.basicDatas;

    const orderData = props.orderData;
    const orderBasicInfos = orderData.basic;
    const cryptoCurrency = orderData.cryptoCurrency;
    const network = orderData.network;
    const paymentMethod = orderData.paymentMethod;

    let amountReceived = orderBasicInfos.networkFee ? (parseFloat(orderBasicInfos.cryptoQte as string) - parseFloat(orderBasicInfos.networkFee + '')) : orderBasicInfos.cryptoQte;
    amountReceived = readabilityNumber(amountReceived);

    const [addressCopied, setAddressCopied] = useState(false);

    if((orderBasicInfos.status !== 'waiting_payment') && (orderBasicInfos.status !== 'marked_paid'))
    {
        return (
            <>
                <ShowOrderStaus order={orderData} {...props} style={{marginTop:'30px'}} />
                <AdminMessage order={orderData}/>
            </>
        )
    }

    return (
        <div>

            <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginBottom:'20px'}}>
                <Avatar src={publicPath + "images/cft-wallet-address.png"} sx={{width:'150px', height:'150px', borderRadius:'2px'}} />
            </div>

            <Tooltip title="Cliquez pour copier l'adresse">
                <Chip
                        label={ cftTokenInfo.ownerAddress }
                        avatar={
                            !addressCopied 
                            ? <ContentCopyIcon sx={{fontSize:'small'}} />
                            : <CheckCircleIcon className="text-success" sx={{fontSize:'small'}} />
                        }
                        variant="outlined"
                        sx={{fontWeight:'bold', fontSize:'15px', marginTop:'10px', cursor:'pointer'}}
                        onClick={() => {copyClipboard(cftTokenInfo.ownerAddress, "Adresse copiée !"); setAddressCopied(true)}}
                    />
            </Tooltip>

            <div style={{fontWeight:'bold', marginTop:'15px', fontSize:'25px'}}>
                { readabilityNumber(orderBasicInfos.fiatQte) + " " + cftTokenInfo.symbol}
            </div>

            {
                orderBasicInfos.networkFee &&

                <TableContainer component={Paper}  sx={{mt:4}}>
                    <Table aria-label="customized table">

                        <TableBody>

                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Quantité crypto
                                </StyledTableCell>
                                <StyledTableCell 
                                    align="left"
                                    sx={{cursor: 'pointer'}}
                                    onClick={() => copyClipboard(orderBasicInfos.cryptoQte + '', "Montant copié !")}
                                    >
                                    <b>{ readabilityNumber(orderBasicInfos.cryptoQte) + " " + cryptoCurrency.symbol}</b>
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Frais réseau

                                    <InfoPopup basicDatas={basicDatas}
                                                title="Pourquoi les frais de réseau ?"
                                                text={
                                                    <div>
                                                        Ces frais sont dus au fait que les frais de gas sur le réseau { network.symbol + " (" + network.name + ")" } sont élévés. Alors l'utilisateur paye une partie et CRYPTO-FIAT prend en charge le reste.

                                                        <br /> <br />

                                                        <div style={{textAlign: 'right'}} className='small'>
                                                            <a href="https://academy.binance.com/fr/articles/what-are-blockchain-transaction-fees" target={'_blank'}>
                                                                C'est quoi les frais de gas ?
                                                            </a>
                                                        </div>
                                                    </div>
                                                }>
                                        <span   className='small text-primary'
                                                style={{
                                                    marginLeft: '20px',
                                                    fontStyle: 'oblique',
                                                    cursor: 'pointer',
                                                    float: 'right'
                                                }}> 
                                                    Pourquoi ces frais ?
                                        </span>
                                    </InfoPopup>

                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    <b>{ readabilityNumber(orderBasicInfos?.networkFee) + " " + cryptoCurrency.symbol }</b>
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Montant net à recevoir
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    <b>{ amountReceived +  " " + cryptoCurrency.symbol }</b>
                                </StyledTableCell>
                            </StyledTableRow>
                        
                        </TableBody>
                    </Table>
                </TableContainer>
            }

            {
                ((orderBasicInfos.status === 'waiting_payment') && (!location.search.match('recap'))) &&

                <Alert severity="warning" sx={{marginTop:'30px', marginBottom:'20px', textAlign:'left'}}>
                    
                    <AlertTitle style={{fontWeight:'bold'}}>Geste important</AlertTitle>
                    Votre ordre a été créé avec succès. Mais avant de procéder au paiement, nous vous invitons à jetter un dernier coup d'oeil sur le 
                    <Link to={"/orders/" + orderBasicInfos.orderID + "/"} className="link text-primary"> récapitulatif</Link>.

                    <br />

                    <div style={{textAlign:'right', marginTop:'10px'}}>
                        <Link to={"/orders/" + orderBasicInfos.orderID + "/"} className="link">
                            <Button variant='contained' color='inherit'>
                                Voir le récapitulatif
                            </Button>
                        </Link>
                    </div>

                </Alert>
            }

            <ShowOrderStaus order={orderData} {...props} style={{marginTop:'30px'}} />

            <ListInstructions
                instrctions={[
                    {
                        step: 1,
                        title: "Effectuez le paiement",
                        content: (
                            <>
                                Envoyez <b>{ readabilityNumber(orderBasicInfos.fiatQte) + " " + cftTokenInfo.symbol + " (" + cftTokenInfo.name + ")"}</b> à l'adresse
                                <b>{" " + cftTokenInfo.ownerAddress}</b> sur le réseau <b>{cftTokenInfo.network}</b>.
                            </>
                        )
                    },

                    {
                        step: 2,
                        title: "Marquez l'ordre comme étant payé",
                        content: (
                            <>
                                Cliquez sur <b>MARQUER PAYÉ</b> en bas de page. <br />
                                {   orderBasicInfos.status === 'waiting_payment' && 
                                    <Typography variant='caption' color='error'>Si vous ne marquez pas comme étant payé, l'ordre ne sera pas traité et finira par être annulé pour delai dépassé.</Typography>
                                }
                            </>
                        )
                    }
                ]}

                receptionAddressNote={
                    <>
                        Lorsque nous recevons vos fonds, vous recevrez <b className='cursor-pointer' onClick={() => copyClipboard(amountReceived as string, "La quantité a été copiée !")}>{ amountReceived + " " + cryptoCurrency.symbol + " " }</b>
                        dans votre portefeuille crypto à l'adresse  <b className='cursor-pointer' onClick={() => copyClipboard(orderBasicInfos.blockchainAddress, "L'adresse a été copié dans le presse-paier.")}>{orderBasicInfos.blockchainAddress}</b>
                        {orderBasicInfos.memo && <span> memo :  <b className='cursor-pointer' onClick={() => copyClipboard(orderBasicInfos.memo ? orderBasicInfos.memo : '', "Le memo a été copié dans le presse-paier.")}>{ orderBasicInfos.memo }</b></span>} sur le réseau <b>{ network.symbol }</b>.
                    </>
                }
                note={
                    <>
                        <b>Note :</b> Veuillez noter que si nous ne recevons pas votre paiement dans un delai de <b>{ paymentMethod.maxTimePaymentLimit + ' minutes' }</b> à partir du moment où l'ordre a été créé, il sera automatiquent annulé.
                    </>
                }
            />

            <br />

            {
                orderBasicInfos.status === 'waiting_payment' &&

                <>
                    <Button type='button' variant='contained' startIcon={<DoneAllIcon />} onClick={props.confirmPayment}>
                        MARQUER PAYÉ
                    </Button>

                    <br /> <br /> <br /> <br />

                    <Button type='button' variant='contained' color='inherit' startIcon={<CancelIcon fontSize='small' color='error'/>} onClick={props.cancelOrder}>
                        Annuler l'ordre
                    </Button>
                </>

            }

            
        </div>
    )
}
